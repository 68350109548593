import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public detail = false;
  private url = 'https://solutions.rolandberger.com/admin/public/_'; // 192.168.42.28

  constructor(
    private http: Http
  ) { }

  public async getCompetenceCenter(id?: number): Promise<any> {
    const prom = new Promise(async (resolve, reject) => {
      try {
        let res: any;
        if (id) {
          res = await this.http.get(this.url + '/items/competence_center/' + id + '?filter[active][eq]=1').toPromise();
        } else {
          res = await this.http.get(this.url + '/items/competence_center?filter[active][eq]=1').toPromise();
        }
        const resData = res.json();
        // Get Images
        resData.data.forEach(async data => {
          if (data.cover) {
            res = await this.http.get(this.url + '/files/' + data.cover).toPromise();
            const cover = res.json();
            data.cover_image = cover.data.data.full_url;
          }
        });
        resolve(resData.data);
      } catch (error) {
        reject(error);
      }
    });
    return await prom;
  }

  public async getTopicGroups(id?: number): Promise<any> {
    const prom = new Promise(async (resolve, reject) => {
      try {
        let res: any;
        if (id) {
          res = await this.http.get(this.url + '/items/topic_groups?filter[active][eq]=1&filter[competence_center][eq]=' + id).toPromise();
        } else {
          res = await this.http.get(this.url + '/items/topic_groups?filter[active][eq]=1').toPromise();
        }
        const resData = res.json();
        resolve(resData.data);
      } catch (error) {
        reject(error);
      }
    });
    return await prom;
  }

  public async getTopics(ids?: number[], cc: any = 11): Promise<any> {
    const prom = new Promise(async (resolve, reject) => {
      try {
        let res: any;
        if (ids) {
          res = await this.http.get(this.url + '/items/topics?fields=id,title,active,primary,has_content,icon,position,competence_center_group,2nd_competence_center_group,divider_direction&filter[active][eq]=1&filter[competence_center_group][in]=' + ids.join()).toPromise();
        } else {
          res = await this.http.get(this.url + '/items/topics?fields=id,title,active,primary,has_content,icon,position,competence_center_group,2nd_competence_center_group,divider_direction&filter[active][eq]=1').toPromise();
        }
        const resData = res.json();

        // Create all tiles
        const array: any[] = [];
        for (let index = 0; index < cc.layout_columns * 4; index++) {
          array.push({
            position: index,
            active: false
          });
        }
        for (let i = 0, l = array.length; i < l; i++) {
          for (let j = 0, ll = resData.data.length; j < ll; j++) {
            if (array[i].position === Number(resData.data[j].position)) {
              array.splice(i, 1, resData.data[j]);
              break;
            }
          }
        }
        resData.mobileData = resData.data.sort((a, b) => {
          const p1 = a.primary ? 1 : 0;
          const p2 = b.primary ? 1 : 0;
          const c1 = a['2nd_competence_center_group'] !== null ? 2 : 1;
          const c2 = b['2nd_competence_center_group'] !== null ? 2 : 1;

          if (a.competence_center_group < b.competence_center_group) { return -1; }
          if (a.competence_center_group > b.competence_center_group) { return 1; }
          if (p1 < p2) { return 1; }
          if (p1 > p2) { return -1; }
          if (c1 < c2) { return -1; }
          if (c1 > c2) { return 1; }
        });
        console.log(resData.mobileData);
        resData.data = array.map((o: any) => { o.position = Number(o.position); return o; }).sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0));
        resolve(resData);
      } catch (error) {
        reject(error);
      }
    });
    return await prom;
  }

  public async getTopic(id?: number): Promise<any> {
    const prom = new Promise(async (resolve, reject) => {
      try {
        let res: any;
        let ids: any;
        if (id) {
          res = await this.http.get(this.url + '/items/topics?single=1&filter[active][eq]=1&filter[id][eq]=' + id).toPromise();
        } else {
          res = await this.http.get(this.url + '/items/topics?single=1&filter[active][eq]=1').toPromise();
        }
        const resData = res.json();
        res = await this.http.get(this.url + '/items/topics_partners?filter[topics_id][eq]=' + id).toPromise();
        const partnerRelationData = res.json();
        res = await this.http.get(this.url + '/items/topics_references?filter[topics_id][eq]=' + id).toPromise();
        const referencesRelationData = res.json();
        res = await this.http.get(this.url + '/items/topics_contacts?filter[topics_id][eq]=' + id).toPromise();
        const contactsRelationData = res.json();

        // Get partners
        ids = partnerRelationData.data.map((o: any) => o.references_and_partners_id);
        res = await this.http.get(this.url + '/items/references_and_partners?filter[id][in]=' + ids.join()).toPromise();
        const partnerData = res.json();

        // Get references
        ids = referencesRelationData.data.map((o: any) => o.contacts_id);
        res = await this.http.get(this.url + '/items/references_and_partners?filter[id][in]=' + ids.join()).toPromise();
        const referencesData = res.json();

        // Get contacts
        ids = contactsRelationData.data.map((o: any) => o.contacts_id);
        res = await this.http.get(this.url + '/items/contacts?filter[id][in]=' + ids.join()).toPromise();
        const contactsData = res.json();

        // Get contact-images
        ids = contactsData.data.map((o: any) => o.profile_picture);
        res = await this.http.get(this.url + '/files?filter[id][in]=' + ids.join()).toPromise();
        const contactImagesData = res.json();

        // Join contact-images
        contactsData.data.forEach(element => {
          element.profile_picture = contactImagesData.data.find((o: any) => o.id === element.profile_picture).data.full_url;
          switch (Number(element.position)) {
            case 1:
              element.position_title = 'Partner';
              element.position_sort = 2;
              break;
            case 2:
              element.position_title = 'Principal';
              element.position_sort = 3;
              break;
            case 3:
              element.position_title = 'Project Manager';
              element.position_sort = 5;
              break;
            case 4:
              element.position_title = 'Senior Partner';
              element.position_sort = 1;
              break;
            case 5:
              element.position_title = 'Senior PM';
              element.position_sort = 4;
              break;
            case 6:
              element.position_title = 'Senior Advisor';
              element.position_sort = 6;
              break;
            default:
              break;
          }
        });

        // Get video
        if (resData.data.showroom) {
          res = await this.http.get(this.url + '/files?single=1&filter[id][eq]=' + resData.data.showroom).toPromise();
          const showroomData = res.json();
          resData.data.showroom = showroomData.data.data.full_url;
        }

        // Join Data
        resData.data.contacts = contactsData.data.sort((a, b) => (a.position_sort > b.position_sort) ? 1 : ((b.position_sort > a.position_sort) ? -1 : 0));
        resData.data.partners = partnerData.data;
        resData.data.references = referencesData.data;
        console.log(resData.data);

        resolve(resData.data);
      } catch (error) {
        reject(error);
      }
    });
    return await prom;
  }

}
