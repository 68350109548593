import { Component, OnInit, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { AppService } from './app.service';
import jQuery from 'jquery';
import { VgAPI, VgFullscreenAPI } from 'videogular2/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('videoPlayer0') public videoplayer0: any;
  @ViewChild('videoPlayer14') public videoplayer14: any;
  @ViewChild('videoPlayer18') public videoplayer18: any;
  public sources: Array<Object>;
  public controls = false;
  public autoplay = false;
  public loop = false;
  public preload = 'auto';
  public api: VgAPI;
  public fsAPI: VgFullscreenAPI;
  public nativeFs = true;
  public showTiles = 0;
  public setTitle = 0;
  public activeNode: any = {};
  public activePage = 0;
  public activePageId = 0;
  public activeSub = 0;
  public activeSubObject: any = {};
  public random: string[] = [];
  public currentData: any = {};
  public nodes: any = [];
  public showSmallHeader = false;
  public loading = false;
  public detail = false;
  public stopForce = false;
  public data: any = [];
  public mainmenu: any = [];

  public topicGroups: any = [];
  public topics: any = [];
  public mobileTopics: any = [];
  public activecc: any = {};
  public tileURL: string;
  public detailURL: string;
  public mobile = false;

  constructor(
    public appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  public async ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    // Get competence center
    this.loading = true;
    const cc: any = await this.appService.getCompetenceCenter();
    this.mainmenu = cc;
    await this.route(this.router.url);
    this.loading = false;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((args: any) => {
      this.route(args.url);
    });
    jQuery('.logo').addClass('in');
  }

  private async route(args: string) {
    console.log(args);
    const url = args.split('/');
    this.tileURL = '/' + url[1];
    if (url[1].length > 0 && url.length === 2) {
      const tmp = this.mainmenu.find((o: any) => o.id === Number(url[1]));
      if (tmp !== this.activecc) {
        await this.getTiles(tmp);
      }
      this.removeData();
      // Change URL
      this.router.navigate([this.tileURL]);
    } else if (url.length === 3) {
      const tmp = this.mainmenu.find((o: any) => o.id === Number(url[1]));
      if (tmp !== this.activecc) {
        await this.getTiles(tmp);
      }
      await this.getData(this.topics.find((o: any) => o.id === Number(url[2])));
    } else {
      this.removeTiles();
      this.removeData();
      // Change URL
      this.router.navigate([]);
    }
  }

  public setTilesURL(cc) {
    // Change URL
    this.tileURL = '/' + cc.id;
    this.router.navigate([this.tileURL]);
  }

  public setDetailURL(node) {
    // Change URL
    this.detailURL = this.tileURL + '/' + node.id;
    this.router.navigate([this.detailURL]);
  }

  public async setActiveNode(node: any, id: number, nodeContent: any) {
    // Content prüfen
    node.items = [{
      id: 0,
      name: 'Showroom',
      icon: 'fa-play-circle'
    }, {
      id: 1,
      name: 'Overview',
      icon: 'fa-compass'
    }, {
      id: 2,
      name: 'Competence',
      icon: 'fa-user-tie'
    }, {
      id: 3,
      name: 'References',
      icon: 'fa-handshake'
    }];

    if (!nodeContent.showroom) {
      node.items = node.items.filter((o: any) => o.id !== 0);
    }
    if (nodeContent.references.length === 0 &&
      nodeContent.partners.length === 0
    ) {
      node.items = node.items.filter((o: any) => o.id !== 3);
    }

    this.activeNode = node;
    if (node.active) {
      [].slice.call(document.querySelectorAll('.hex')).forEach((el, pos) => {
        const rect = el.getBoundingClientRect();
        setTimeout(() => {
          el.classList.remove('out');
        }, (rect.left / 4) + Math.floor((Math.random() * 500) + 1));
      });
      this.nodes.forEach((n: any) => {
        n.active = false;
        n.inactive = false;
      });
    } else {
      this.nodes.forEach((n: any) => {
        n.active = false;
        n.inactive = true;
      });
      /*this.nodes[index].active = true;
      this.nodes[index].inactive = false;*/
      [].slice.call(document.querySelectorAll('.hex')).forEach((el, pos) => {
        const rect = el.getBoundingClientRect();
        setTimeout(() => {
          if (el.className.indexOf(' active') === -1) {
            el.classList.add('out');
          }
        }, (rect.left / 4) + Math.floor((Math.random() * 500) + 1));
      });
    }
  }

  public async getData(node: any): Promise<any> {
    if (node.title && node.active && node.has_content) {

      if (this.mobile) {
        jQuery('body').css('overflow', 'hidden');
      }

      this.loading = true;
      this.showSmallHeader = false;
      this.activePage = 0;

      // Get data
      const result = await this.appService.getTopic(node.id);
      this.currentData = result;
      console.log(this.currentData);

      this.setActiveNode(node, node.id, this.currentData);
      this.animatePage(node.items[0].id);
      this.appService.detail = true;
      jQuery('.logo').removeClass('in');

      this.activePageId = node.items[0].id;
      this.loading = false;
    }
  }

  public removeData(): void {
    if (this.mobile) {
      jQuery('body').css('overflow', 'inherit');
    }
    /*const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);*/
    jQuery('.bg2').removeClass('in');
    jQuery('.bg').removeClass('in');
    jQuery('.back').removeClass('in');
    setTimeout(() => {
      this.appService.detail = false;
    }, 750);
    jQuery('.logo').addClass('in');
    // Change URL
    this.router.navigate([this.tileURL]);
  }

  getColorByTopicGroup(id: number): string {
    const result: any = this.topicGroups.find((o: any) => o.id === id);
    if (result) {
      return result.color;
    } else {
      return null;
    }
  }

  public getClassFill(node: any, suffix: string = ''): any {
    if (node) {
      const color: any = this.getColorByTopicGroup(node.competence_center_group);
      return { 'fill': color, 'color': color };
    }
  }

  public getClassBorder(node: any, suffix: string = ''): any {
    if (node) {
      const color: any = this.getColorByTopicGroup(node.competence_center_group);
      return { 'border-color': color };
    }
  }

  public getClassBackground(node: any, suffix: string = ''): any {
    if (node) {
      const color: any = this.getColorByTopicGroup(node.competence_center_group);
      return { 'background-color': color };
    }
  }

  public getClassLink(node: any, suffix: string = ''): any {
    if (node) {
      const color: any = this.getColorByTopicGroup(node['2nd_competence_center_group']);
      return { 'fill': color, 'color': color };
    }
  }

  public getClassStroke(node: any, suffix: string = ''): string {
    if (node) {
      return 'stroke' + node.group + suffix;
    }
  }

  public getSanName(node): string {
    if (node.name) {
      return node.name.toLowerCase().replace(/[-/\s]/g, '_');
    }
  }

  public onPlayerReady(api: VgAPI) {
    this.api = api;
    this.fsAPI = this.api.fsAPI;
    this.nativeFs = this.fsAPI.nativeFullscreen;

    this.api.getDefaultMedia().subscriptions.ended.subscribe(
      () => {
        // Set the video to the beginning
        this.api.getDefaultMedia().currentTime = 0;
      }
    );
  }

  public async getTiles(cc: any): Promise<any> {
    let tilesCount = 0;
    this.activecc = cc;
    this.loading = true;

    // Get Topic Groups
    this.topicGroups = await this.appService.getTopicGroups(cc.id);

    // Get Topics
    const topicGroupIDs = this.topicGroups.map((o: any) => o.id);
    this.topics = await this.appService.getTopics(topicGroupIDs, cc);
    this.mobileTopics = this.topics.mobileData;
    this.topics = this.topics.data;

    jQuery('.main').addClass('out');
    setTimeout(() => {
      [].slice.call(document.querySelectorAll('.my-grid-item')).forEach((el) => {
        const rect = el.getBoundingClientRect();
        tilesCount++;
        setTimeout(() => {
          el.classList.add('in');
        }, (rect.left / 4) + Math.floor((Math.random() * 350) + 1));
      });
      const rows = tilesCount / 4;
      if (window.innerWidth < 1200) {
        $('.wrapper').css('transform', 'scale(' + 1 / (rows / 7) + ')');
      }

      // set Layout columns
      jQuery('.my-grid').css('grid-template-columns', 'repeat(' + cc.layout_columns + ', 1fr 2fr) 1fr');
      jQuery('.my-grid').css('width', (6.815 * cc.layout_columns) + '%');
      this.loading = false;
    });
  }

  public removeTiles(): void {
    jQuery('.main').removeClass('out');
    [].slice.call(document.querySelectorAll('.my-grid-item')).forEach((el) => {
      const rect = el.getBoundingClientRect();
      setTimeout(() => {
        el.classList.remove('in');
      }, (rect.left / 8) + Math.floor((Math.random() * 50) + 1));
    });
    this.activecc = {};
  }

  public setPage(index: number, item: any) {
    jQuery('.content').animate({ scrollTop: 0 }, 500);
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
    if (index > 0) {
      try {
        this.api.pause();
      } catch (error) {
        //
      }
    }
    let offset = window.innerWidth;
    offset = offset * index;
    this.activePage = index;
    this.activePageId = item.id;
    jQuery('.slides-wrapper').attr('style', 'transform: translateX(-' + offset + 'px)');
  }

  public animatePage(pageId: number) {
    setTimeout(() => {
      jQuery('.bg2').addClass('in');
      jQuery('.bg').addClass('in');
      jQuery('.back').addClass('in');
    }, 500);
  }

  public moveHex(el: any): void {
    if (!this.appService.detail) {
      const time: number = Math.floor((Math.random() * 1000) + 3000);
      const x: number = Math.floor((Math.random() * 6) + 1) - 3;
      const y: number = Math.floor((Math.random() * 6) + 1) - 3;
      jQuery(el).attr('style', 'transform: translate(' + x + 'px, ' + y + 'px); transition: all ' + time + 'ms;');
      setTimeout(() => {
        this.moveHex(el);
      }, time);
    }
  }

  public subHover(id: number, item: any) {
    if (this.showTiles === 0) {
      this.activeSub = id;
      if (id > 0) {
        this.activeSubObject = item;
      } else {
        this.activeSubObject = {};
      }
    }
  }

  public reset(): void {
    this.router.navigate(['/']);
  }

  public playVideo(id: number): void {
    this['videoplayer' + id].nativeElement.play();
  }

  public stopVideo(id: number): void {
    if (!this.appService.detail) {
      // this['videoplayer' + id].nativeElement.pause();
    }
  }

  public getTitle(): any {
    return this.activeSubObject.title;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 767) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.changeDetectorRef.detectChanges();
  }
}
