import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

const routes: Routes = [
  { path: '', component: AppComponent },
  { path: ':ccId', component: AppComponent },
  { path: ':ccId/:topicId', component: AppComponent }
];

export const AppRoutes = RouterModule.forChild(routes);
